.post {
  width: 95%;
  margin: 0.5rem;
}

.post-body {
  padding-bottom: 0.5rem;
}

.like-btn .post-title {
  margin: 0.5rem 0 0.5rem 0;
}

.like-hoof {
  width: 2rem;
  height: auto;
}

.profile-pic {
  width: 100%;
  height: auto;
}

.edit-btn {
  margin: 0.4rem 0 0.4rem 0;
}

a.edit-link {
  color: black;
  
}

a.edit-link:hover {
  text-decoration: none;
}

.post-col {
  padding: 0 5px 0 5px;
}